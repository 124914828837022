<template>
    <div>
        <div class="row page-titles mx-0">
            <div class="col-md-6 p-md-0">
                <div class="welcome-text">
                    <h4>Applications</h4>
                </div>
            </div>
            <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'Applications' }">Applications</router-link>
                    </li>
                    <li class="breadcrumb-item active">Edit</li>
                </ol>
            </div>
        </div>

        <div class="row tab-content">

            <div id="list-view" class="tab-pane fade active show col-lg-12">
                <div class="card top-red-border">
                    <div class="card-header">
                        <h4 class="card-title">Edit the Application <h4></h4>
                        </h4>
                        <router-link v-canAny="'status_applications,payment_status_applications'"
                            :to="{ name: 'Applications-Statuses-Management', props: { 'id': application.id } }"
                            class="btn btn-primary">Statuses Management</router-link>
                    </div>
                    <div class="card-body">
                        <!-- FeedBack -->
                        <feedback-messages :msgs="msgs" :errors="errors" />
                        <!-- /FeedBack -->
                        <!-- /FeedBack -->
                        <div class="basic-form">
                            <form @submit.prevent="submitFormData()">

                                <!-- Statuses -->
                                <div class="student-information">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Status</label>
                                    </div>
                                    <div class="custom-left-tall-border">
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Payment Status</label>
                                            <div class="col-md-4">
                                                <p><span>{{ application.payment_status }}</span></p>
                                            </div>
                                            <label class="col-md-2 col-form-label">Application Status</label>
                                            <div class="col-md-4">
                                                <p>{{ application.status }}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- Student Information -->
                                <div class="student-information">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Student Information</label>
                                    </div>
                                    <div class="custom-left-tall-border">
                                        <!-- Fullname [en]-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Name [English] <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required
                                                    v-model="application.firstname_en" name="firstname_en"
                                                    placeholder="First Name in English">
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required
                                                    v-model="application.middlename_en" name="middlename_en"
                                                    placeholder="Middle Name in English">
                                            </div>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="application.lastname_en" name="lastname_en"
                                                    placeholder="Family Name in English">
                                            </div>
                                        </div>
                                        <!-- Fullname [Ar]-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Name [Arabic] <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required
                                                    v-model="application.firstname_ar" name="firstname_ar"
                                                    placeholder="First Name in Arabic">
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required
                                                    v-model="application.middlename_ar" name="middlename_ar"
                                                    placeholder="Middle Name in Arabic">
                                            </div>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="application.lastname_ar" name="lastname_ar"
                                                    placeholder="Family Name in Arabic">
                                            </div>
                                        </div>
                                        <!-- Country and City of the Birth -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Country of Birth <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="application.birth_country_id"
                                                    name="birth_country_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="country in lists.countries" :key="country.id"
                                                        :value="country.id">{{ country.value }}
                                                    </option>
                                                </select>
                                            </div>
                                            <label class="col-md-2 col-form-label">City of Birth <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="application.birth_city_name" name="age"
                                                    placeholder="City of Birth">
                                            </div>
                                        </div>
                                        <!-- Age and Gender -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Age at First of Next October <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required v-model="application.age"
                                                    name="age" placeholder="Age">
                                            </div>
                                            <label class="col-md-2 col-form-label">Gender <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="application.gender"
                                                    name="gender">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- Nationality and Religion-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Nationality <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required @change="checkStdNationality($event)"
                                                    v-model="application.nationality_id" name="nationality_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="nationality in lists.nationalities"
                                                        :data-identificationType="nationality.identification_type"
                                                        :key="nationality.id" v-bind:value="nationality.id">
                                                        {{ nationality.value }} </option>
                                                </select>
                                                <input v-if="flags.stdNationalID" required type="text" class="form-control"
                                                    v-model="application.national_id" name="national_id"
                                                    placeholder="National ID">
                                                <input v-if="flags.stdPassport" required type="text" class="form-control"
                                                    v-model="application.passport" name="passport"
                                                    placeholder="Passport Number">
                                            </div>
                                            <label class="col-md-2 col-form-label">Religion <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required @change="checkIfStdOtherReligion"
                                                    v-model="application.religion" name="religion">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="religion in lists.religions" :key="religion.id"
                                                        v-bind:value="religion">
                                                        {{ religion }}</option>
                                                </select>
                                                <input v-if="flags.isStdOtherReligion" required type="text"
                                                    class="form-control" v-model="application.std_other_religion"
                                                    name="std_other_religion" placeholder="Please specify the other">
                                            </div>
                                        </div>

                                        <!-- Second Language and Transportation -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Second Language<span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required
                                                    v-model="application.second_language_id" name="second_language_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(language) in lists.languages" :key="language.id"
                                                        v-bind:value="language.id">
                                                        {{ language.value }}</option>
                                                </select>
                                            </div>
                                            <label class="col-md-2 col-form-label">Transportation <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required
                                                    v-model="application.transportation_type" name="transportation_type">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(type, index) in lists.transportation_types" :key="index"
                                                        v-bind:value="type">
                                                        {{ type }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- Previous School and Last Grade -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Previous School <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" required class="form-control"
                                                    v-model="application.previous_school" name="previous_school"
                                                    placeholder="Previous School Name">
                                            </div>
                                            <label class="col-md-2 col-form-label">Last Grade <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" required class="form-control"
                                                    v-model="application.last_grade" name="last_grade"
                                                    placeholder="Last Grade Value">
                                            </div>
                                        </div>
                                        <!-- Currently in system and Applying to Join -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Currently in system <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="application.current_system"
                                                    name="current_system">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(system, index) in lists.systems" :key="index"
                                                        v-bind:value="system">
                                                        {{ system }}</option>
                                                </select>
                                            </div>
                                            <label class="col-md-2 col-form-label">Applying to Join <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="application.apply_level_id"
                                                    name="apply_level_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="level in lists.levels" :key="level.id"
                                                        v-bind:value="level.id">
                                                        {{ level.value }}</option>

                                                </select>
                                            </div>
                                        </div>
                                        <!-- Medical History and Special Education Supports -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Medical History <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <textarea class="form-control" required
                                                    v-model="application.medical_history" name="medical_history"
                                                    placeholder="Ex: Allergies, Asthma, Diabetes, Previous Surgery, Taking Medications, ..etc"></textarea>
                                            </div>
                                            <label class="col-md-2 col-form-label">Special Education Supports<span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required
                                                    v-model="application.special_education_need"
                                                    name="special_education_need" @change="checkIfSpecialEducationNeeded()">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                                <textarea v-if="application.isSpecialEducationNeeded" class="form-control"
                                                    required v-model="application.special_education_need_details"
                                                    name="special_education_need_details"
                                                    placeholder="Give Details if any needed help"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Current and Previous Schools -->
                                <div class="previous-schools" style="padding-bottom:20px">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Current and Previous Schools</label>
                                    </div>

                                    <div class="custom-left-tall-border"
                                        v-for="(school, index) in application.previous_schools" :key="index">
                                        <!-- Delete the block -->
                                        <div v-if="index != 0" class="form-group row">
                                            <div class="col-md-2">
                                                <a @click.prevent="removeSchoolBlock(index)" href="#" class="btn btn-danger"
                                                    title="Rmove"><i class="la la-times"></i>
                                                </a><br>
                                            </div>
                                        </div>

                                        <!-- School Name and Type -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">School Name <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required v-model="school.name"
                                                    placeholder="Name in English">
                                            </div>
                                            <label class="col-md-2 col-form-label">Type <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="school.type">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(system, index) in lists.systems" :key="index"
                                                        v-bind:value="system">
                                                        {{ system }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- Phone and Address -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Phone <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required v-model="school.phone"
                                                    placeholder="Phone">
                                            </div>
                                            <label class="col-md-2 col-form-label">Address <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <textarea class="form-control" required v-model="school.address"
                                                    placeholder="Address"></textarea>
                                            </div>
                                        </div>
                                        <!-- From Date and Current School-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">From (Date) <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="date" class="form-control" required v-model="school.from"
                                                    placeholder="From (Date)">
                                            </div>
                                            <label class="col-md-2 col-form-label">Current School <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="school.current"
                                                    @change="checkIfCurrentSchool(index)">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- To Date -->
                                        <div v-if="!school.isCurrent" class="form-group row">
                                            <label class="col-md-2 col-form-label">To (Date) <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="date" class="form-control" required v-model="school.to"
                                                    placeholder="To (Date)">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Add new block -->
                                    <div class="from-group row">
                                        <div class="col-md-2">
                                            <a @click.prevent="addAnotherSchoolBlock" href="#"
                                                class="btn btn-secondary-original" onclick="this.blur();"><i
                                                    class="la la-plus"></i>
                                                Another School</a><br>
                                        </div>
                                    </div>

                                </div>


                                <!-- Father Information -->
                                <div class="father-information" style="padding-bottom:20px">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Father Information</label>
                                    </div>

                                    <div class="custom-left-tall-border">
                                        <!-- Father: Full Name -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Name [English] <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="application.father_name" name="father_name"
                                                    placeholder="Full Name">
                                            </div>
                                            <label class="col-md-2 col-form-label">Email <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="email" class="form-control" required
                                                    v-model="application.father_email" name="father_email"
                                                    placeholder="Email">
                                            </div>
                                        </div>
                                        <!-- Father: Nationality and Religion-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Nationality <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required
                                                    @change="checkFatherNationality($event)"
                                                    v-model="application.father_nationality_id"
                                                    name="father_nationality_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="nationality in lists.nationalities"
                                                        :data-identificationType="nationality.identification_type"
                                                        :key="nationality.id" v-bind:value="nationality.id">
                                                        {{ nationality.value }}</option>
                                                </select>
                                                <input v-if="flags.fatherNationalID" type="text" class="form-control"
                                                    required v-model="application.father_national_id"
                                                    name="father_national_id" placeholder="National ID">
                                                <input v-if="flags.fatherPassport" type="text" class="form-control" required
                                                    v-model="application.father_passport" name="father_passport"
                                                    placeholder="Passport Number">
                                            </div>
                                            <label class="col-md-2 col-form-label">Religion <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required @change="checkIfFatherOtherReligion"
                                                    v-model="application.father_religion" name="father_religion">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="religion in lists.religions" :key="religion.id"
                                                        v-bind:value="religion">
                                                        {{ religion }}</option>
                                                </select>
                                                <input v-if="flags.isFatherOtherReligion" type="text" class="form-control"
                                                    required v-model="application.father_other_religion"
                                                    name="father_other_religion" placeholder="Please specify the other">
                                            </div>
                                        </div>
                                        <!-- Father: Home and work Address -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Home Address <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <textarea class="form-control" required
                                                    v-model="application.father_home_address" name="father_home_address"
                                                    placeholder="Home Address"></textarea>
                                            </div>
                                            <label class="col-md-2 col-form-label">Work Address <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <textarea class="form-control" required
                                                    v-model="application.father_work_address" name="father_work_address"
                                                    placeholder="Work Address"></textarea>
                                            </div>
                                        </div>
                                        <!-- Father: Home Tel. - Mobile -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Home Tel. <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="application.father_home_phone" name="father_home_phone"
                                                    placeholder="Home Tel.">
                                            </div>
                                            <label class="col-md-2 col-form-label">Mobile <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="number" class="form-control" required
                                                    v-model="application.father_mobile" name="father_mobile"
                                                    placeholder="Mobile">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Mother Information -->
                                <div class="mother-information" style="padding-bottom:20px">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Mother Information</label>
                                    </div>

                                    <div class="custom-left-tall-border">
                                        <!-- Mother: Full Name -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Name [English] <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="application.mother_name" name="mother_name"
                                                    placeholder="Full Name">
                                            </div>
                                            <label class="col-md-2 col-form-label">Email <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="email" class="form-control" required
                                                    v-model="application.mother_email" name="mother_email"
                                                    placeholder="Email">
                                            </div>
                                        </div>
                                        <!-- Mother: Nationality and Religion-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Nationality <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" @change="checkMotherNationality($event)"
                                                    v-model="application.mother_nationality_id"
                                                    name="mother_nationality_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="nationality in lists.nationalities"
                                                        :data-identificationType="nationality.identification_type"
                                                        :key="nationality.id" v-bind:value="nationality.id">
                                                        {{ nationality.value }}</option>
                                                </select>
                                                <input v-if="flags.motherNationalID" type="text" class="form-control"
                                                    v-model="application.mother_national_id" name="mother_national_id"
                                                    placeholder="National ID">
                                                <input v-if="flags.motherPassport" type="text" class="form-control"
                                                    v-model="application.mother_passport" name="mother_passport"
                                                    placeholder="Passport Number">
                                            </div>
                                            <label class="col-md-2 col-form-label">Religion <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" @change="checkIfMotherOtherReligion"
                                                    v-model="application.mother_religion" name="mother_religion">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="religion in lists.religions" :key="religion.id"
                                                        v-bind:value="religion">
                                                        {{ religion }}</option>
                                                </select>
                                                <input v-if="flags.isMotherOtherReligion" type="text" class="form-control"
                                                    v-model="application.mother_other_religion" name="mother_other_religion"
                                                    placeholder="Please specify the other">
                                            </div>
                                        </div>
                                        <!-- Mother: Home and work Address -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Home Address <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <textarea class="form-control" required
                                                    v-model="application.mother_home_address" name="mother_home_address"
                                                    placeholder="Home Address"></textarea>
                                            </div>
                                            <label class="col-md-2 col-form-label">Work Address <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <textarea class="form-control" required
                                                    v-model="application.mother_work_address" name="mother_work_address"
                                                    placeholder="Work Address"></textarea>
                                            </div>
                                        </div>
                                        <!-- Mother: Home Tel. - Mobile -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Home Tel. <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="application.mother_home_phone" name="mother_home_phone"
                                                    placeholder="Home Tel.">
                                            </div>
                                            <label class="col-md-2 col-form-label">Mobile <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="number" class="form-control" required
                                                    v-model="application.mother_mobile" name="mother_mobile"
                                                    placeholder="Mobile">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Emergency Contact -->
                                <div class="emergency-contact" style="padding-bottom:20px">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Emergency Contact</label>
                                    </div>
                                    <div class="custom-left-tall-border"
                                        v-for="(contact, index) in application.emergency_contacts" :key="index">
                                        <!-- Delete the block -->
                                        <div v-if="index != 0" class="form-group row">
                                            <div class="col-md-2">
                                                <a @click.prevent="removeEmergencyContactBlock(index)" href="#"
                                                    class="btn btn-danger" title="Rmove"><i class="la la-times"></i>
                                                </a><br>
                                            </div>
                                        </div>
                                        <!-- Emergency contact person -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">The Person <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required
                                                    @change="checkIfOtherEmergencyContact(index)" v-model="contact.person"
                                                    name="person">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(person, i) in lists.contact_persons" :key="i"
                                                        :value="person">{{ person }}</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div v-if="contact.isOther">
                                            <!-- Name and Address -->
                                            <div class="form-group row">
                                                <label class="col-md-2 col-form-label">Name <span
                                                        style="color:red">*</span></label>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" required v-model="contact.name"
                                                        placeholder="Name">
                                                </div>
                                                <label class="col-md-2 col-form-label">Relation to the Student <span
                                                        style="color:red">*</span></label>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" required
                                                        v-model="contact.relation_to_std"
                                                        placeholder="Please specify the relation.">
                                                </div>
                                            </div>

                                            <!-- Home Tel. - Mobile -->
                                            <div class="form-group row">
                                                <label class="col-md-2 col-form-label">Home Tel. <span
                                                        style="color:red">*</span></label>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" required v-model="contact.phone"
                                                        placeholder="Home Tel.">
                                                </div>
                                                <label class="col-md-2 col-form-label">Mobile <span
                                                        style="color:red">*</span></label>
                                                <div class="col-md-4">
                                                    <input type="number" class="form-control" required
                                                        v-model="contact.mobile" placeholder="Mobile">
                                                </div>
                                            </div>

                                            <!-- Address -->
                                            <div class="form-group row">
                                                <label class="col-md-2 col-form-label">Address</label>
                                                <div class="col-md-4">
                                                    <textarea class="form-control" required v-model="contact.address"
                                                        placeholder="Address"></textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <!-- Add new block -->
                                    <div class="from-group row">
                                        <div class="col-md-2">
                                            <a @click.prevent="addEmergencyContactBlock" href="#"
                                                class="btn btn-secondary-original" onclick="this.blur();"><i
                                                    class="la la-plus"></i>
                                                Another Contact</a><br>
                                        </div>
                                    </div>
                                </div>


                                <!-- Family Details -->
                                <div class="family-details" style="padding-bottom:20px">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Family Details</label>
                                    </div>

                                    <div class="custom-left-tall-border">
                                        <!-- Number of Brothers and Number of Sisters -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Number of Brothers <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="number" class="form-control" required
                                                    v-model="application.number_of_brothers" name="number_of_brothers"
                                                    placeholder="Number of Brothers">
                                            </div>
                                            <label class="col-md-2 col-form-label">Number of Sisters <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="number" class="form-control" required
                                                    v-model="application.number_of_sisters" name="number_of_sisters"
                                                    placeholder="Number of Sisters">
                                            </div>
                                        </div>
                                        <!-- Parents Martial State and Legal Custody -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Parents Martial State <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required
                                                    v-model="application.parents_martial_state" @change="checkMartialState"
                                                    name="parents_martial_state">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(state, index) in lists.martial_states" :key="index"
                                                        v-bind:value="state">
                                                        {{ state }}</option>
                                                </select>
                                            </div>
                                            <label class="col-md-2 col-form-label">Legal Custody with <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="application.legal_custody"
                                                    name="legal_custody">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(person, index) in lists.legal_custody_persons"
                                                        :key="index" v-bind:value="person">
                                                        {{ person }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <!-- Attachments -->
                                <div class="Attachments" style="padding-bottom:20px">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Attachments</label>
                                    </div>

                                    <div class="custom-left-tall-border">
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Student Birth Certificate <span
                                                    style="color:red">*</span>
                                                <small v-if="application.student_birth_certificate_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(application.id, 'student_birth_certificate_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*, .pdf, .doc, .docx"
                                                    name="student_birth_certificate_file" class="dropify">

                                            </div>

                                            <label class="col-md-2 col-form-label">Student Vaccination Certificate <span
                                                    style="color:red">*</span>
                                                <small v-if="application.student_vaccination_certificate_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(application.id, 'student_vaccination_certificate_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div class="col-md-4"><input type="file" @change="handleFiles"
                                                    accept="image/*, .pdf, .doc, .docx"
                                                    name="student_vaccination_certificate_file" class="dropify">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Father National ID <span
                                                    style="color:red">*</span>
                                                <small v-if="application.father_national_id_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(application.id, 'father_national_id_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div class="col-md-4"><input type="file" @change="handleFiles"
                                                    accept="image/*, .pdf, .doc, .docx" name="father_national_id_file"
                                                    class="dropify">
                                            </div>
                                            <label class="col-md-2 col-form-label">Mother National ID <span
                                                    style="color:red">*</span>
                                                <small v-if="application.mother_national_id_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(application.id, 'mother_national_id_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div class="col-md-4"><input type="file" @change="handleFiles"
                                                    accept="image/*, .pdf, .doc, .docx" name="mother_national_id_file"
                                                    class="dropify">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label v-if="application.isDivorced" class="col-md-2 col-form-label">Legal
                                                Custody <span style="color:red">*</span>
                                                <small v-if="application.legal_custody_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(application.id, 'legal_custody_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div v-if="application.isDivorced" class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*, .pdf, .doc, .docx"
                                                    name="legal_custody_file" class="dropify">
                                                <br><small style="color:red">Mandatory only in case of Divorced</small>
                                            </div>

                                            <label v-if="application.isSpecialEducationNeeded"
                                                class="col-md-2 col-form-label">Special Education Report <span
                                                    style="color:red">*</span>
                                                <small v-if="application.special_education_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(application.id, 'special_education_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div v-if="application.isSpecialEducationNeeded" class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*, .pdf, .doc, .docx"
                                                    name="special_education_file" class="dropify">
                                                <br><small style="color:red">Mandatory in case of Special Education
                                                    Supports
                                                    Check</small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Profile Picture
                                                <span style="color: red">*</span>
                                                <small v-if="application.img_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(application.id, 'img_url')"
                                                        target="_blank">view</a></small>
                                            </label>
                                            <div class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*" name="img_url"
                                                    class="dropify" />
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div class="form-group row" style=" padding-top: 2rem; margin-bottom: 0px; ">
                                    <div class="col-md-12">
                                        <button :disabled="disableBtn" name="submit" type="submit" class="btn btn-primary"
                                            value="Submit" onclick="this.blur();"> Submit
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import FeedbackMessages from '@/components/FeedbackMessages.vue';
import useApplication from "@/composables/applications";
import {
    useRouter
} from 'vue-router'
import {
    onMounted,
    reactive,
    ref,
    inject,
    onBeforeMount
} from 'vue';

export default {
    props: {
        id: {
            required: true,
            type: Number
        }
    },
    components: {
        FeedbackMessages
    },
    setup(props) {

        const swal = inject('$swal');
        const hasPermission = inject('hasPermission');
        const hasAnyRole = inject('hasAnyRole');
        const router = useRouter();
        const disableBtn = ref(false);
        var formDataObj = new FormData();

        onBeforeMount(() => {
            //first: check the user Permission
            if (!hasPermission('edit_applications'))
                router.push({
                    name: '401'
                })
        });

        const {
            getListsForApplication,
            getApplication,
            application,
            UpdateApplication,
            downloadApplicationFile,
            lists,
            msgs,
            errors,
        } = useApplication();

        const flags = reactive({
            'stdNationalID': false,
            'stdPassport': false,
            'fatherNationalID': false,
            'fatherPassport': false,
            'motherNationalID': false,
            'motherPassport': false,
            'isStdOtherReligion': false,
            'isSpecialEducationNeeded': false,
            'isFatherOtherReligion': false,
            'isMotherOtherReligion': false,
        })

        onMounted(async () => {
            await getApplication(props.id)
            getListsForApplication();

            if (hasAnyRole('Applicant,Parent,Student') && application.value.is_editable_by_parent == 'No')
                router.push({
                    name: '401'
                })
        })

        const addAnotherSchoolBlock = () => {
            application.value.previous_schools.push({
                'name': '',
                'type': '',
                'address': '',
                'phone': '',
                'from': '',
                'to': '',
                'current Box': '',
            });
        }

        const removeSchoolBlock = (index) => {
            application.value.previous_schools.splice(index, 1);
        }

        const addEmergencyContactBlock = () => {
            application.value.emergency_contacts.push({
                'person': '',
                'relation_to_std': '',
                'isOther': false,
                'name': '',
                'mobile': '',
                'phone': '',
                'address': '',
            });
        }

        const removeEmergencyContactBlock = (index) => {
            application.value.emergency_contacts.splice(index, 1);
        }

        const handleFiles = async (e) => {
            let file = e.target.files[0];
            let name = e.target.name;
            formDataObj.append(name, file);
        }

        const checkIfOtherEmergencyContact = (index) => {
            let selectedOption = application.value.emergency_contacts[index].person;
            if (selectedOption == 'Other')
                application.value.emergency_contacts[index].isOther = true;
            else
                application.value.emergency_contacts[index].isOther = false;
        }

        const checkIfStdOtherReligion = () => {

            if (application.value.religion == 'Other')
                flags.isStdOtherReligion = true;
            else
                flags.isStdOtherReligion = false;
        }

        const checkIfFatherOtherReligion = () => {

            if (application.value.father_religion == 'Other')
                flags.isFatherOtherReligion = true;
            else
                flags.isFatherOtherReligion = false;
        }

        const checkIfMotherOtherReligion = () => {

            if (application.value.mother_religion == 'Other')
                flags.isMotherOtherReligion = true;
            else
                flags.isMotherOtherReligion = false;
        }

        const checkStdNationality = (e) => {

            flags.stdNationalID = false;
            flags.stdPassport = false;

            if (e.target.options.selectedIndex > -1) {
                let identificationtype = e.target.options[e.target.options.selectedIndex].dataset
                    .identificationtype;
                if (identificationtype == 'National ID')
                    flags.stdNationalID = true;
                else
                    flags.stdPassport = true;
            }

        }

        const checkFatherNationality = (e) => {

            flags.fatherNationalID = false;
            flags.fatherPassport = false;

            if (e.target.options.selectedIndex > -1) {
                let identificationtype = e.target.options[e.target.options.selectedIndex].dataset
                    .identificationtype;
                if (identificationtype == 'National ID')
                    flags.fatherNationalID = true;
                else
                    flags.fatherPassport = true;
            }

        }

        const checkMotherNationality = (e) => {

            flags.motherNationalID = false;
            flags.motherPassport = false;

            if (e.target.options.selectedIndex > -1) {
                let identificationtype = e.target.options[e.target.options.selectedIndex].dataset
                    .identificationtype;
                if (identificationtype == 'National ID')
                    flags.motherNationalID = true;
                else
                    flags.motherPassport = true;
            }

        }


        const checkIfCurrentSchool = (index) => {
            let selectedOption = application.value.previous_schools[index].current;
            if (selectedOption == 'Yes') {
                application.value.previous_schools[index].isCurrent = true;
                application.value.previous_schools.map(function (value, key) {
                    if (key != index) { //to prevent the user to select more than on current school
                        application.value.previous_schools[key].current = 'No';
                        application.value.previous_schools[key].isCurrent = false;
                    }
                });
            } else {
                application.value.previous_schools[index].isCurrent = false;
            }
        }

        const checkIfSpecialEducationNeeded = async () => {
            if (application.value.special_education_need == 'Yes') {
                application.value.isSpecialEducationNeeded = true;
            } else {
                application.value.isSpecialEducationNeeded = false;
            }
        }

        const checkMartialState = async () => {
            if (application.value.parents_martial_state == 'Divorced') {
                application.value.isDivorced = true;
            } else {
                application.value.isDivorced = false;
            }
        }

        const submitFormData = async () => {

            const applicationArray = Object.entries(application.value);

            applicationArray.forEach((row) => {
                let name = row[0];
                let value = row[1];

                if (Array.isArray(value)) {
                    value = JSON.stringify(value);
                }
                formDataObj.append(name, value);
            });

            swal.fire({
                title: 'Please wait while submit your application',
                allowOutsideClick: false,
                didOpen: () => {
                    swal.showLoading()
                }
            });

            errors.value = [];
            disableBtn.value = true;

            await UpdateApplication(
                formDataObj, props.id
            ).then((resp) => {
                swal.close();
                if (resp != 'fail') {
                    router.push({
                        name: 'Applications-Fees',
                        params: {
                            id: resp
                        }
                    })
                }
                window.scrollTo(0, 0);
                disableBtn.value = false;
            });

        }

        const downloadFile = async (id, filetype) => {
            await downloadApplicationFile(id, filetype);
        }

        return {
            application,
            flags,
            lists,
            msgs,
            errors,
            disableBtn,
            submitFormData,
            addAnotherSchoolBlock,
            removeSchoolBlock,
            addEmergencyContactBlock,
            removeEmergencyContactBlock,
            handleFiles,
            checkIfOtherEmergencyContact,
            checkIfStdOtherReligion,
            checkIfFatherOtherReligion,
            checkIfMotherOtherReligion,
            checkStdNationality,
            checkFatherNationality,
            checkMotherNationality,
            checkIfCurrentSchool,
            checkIfSpecialEducationNeeded,
            checkMartialState,
            downloadFile
        }
    }
}
</script>